'use strict';

Gri.module({
  name: 'box-epsilon',
  ieVersion: null,
  $el: $('.box-epsilon'),
  container: '.box-epsilon',
  fn: function () {
    
  }
});
